var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.featuresByType.length > 0
    ? _c(
        "div",
        [
          _c("LabelForm", { staticClass: "mt-0" }, [
            _vm._v(_vm._s(_vm.$t("admin.authorization.features")))
          ]),
          _c("div", { staticClass: "flex flex-row gap-2 mt-2 mb-4" }, [
            _c(
              "button",
              {
                staticClass:
                  "sk-btn sk-btn-small sk-btn-primary rounded-lg btn-border",
                on: {
                  click: function($event) {
                    return _vm.changeAll(true)
                  }
                }
              },
              [_vm._v(" " + _vm._s(_vm.$t("shops.select_all")) + " ")]
            ),
            _c(
              "button",
              {
                staticClass:
                  "sk-btn sk-btn-small sk-btn-primary rounded-lg btn-border",
                on: {
                  click: function($event) {
                    return _vm.changeAll(false)
                  }
                }
              },
              [_vm._v(" " + _vm._s(_vm.$t("shops.clear")) + " ")]
            )
          ]),
          _vm._l(_vm.featuresByType, function(type) {
            return _c(
              "div",
              { key: type.id },
              [
                _c(
                  "vs-checkbox",
                  {
                    staticClass: "mt-4 text-base",
                    on: {
                      change: function($event) {
                        return _vm.handleTypeChange(type)
                      }
                    },
                    model: {
                      value: type.enabled,
                      callback: function($$v) {
                        _vm.$set(type, "enabled", $$v)
                      },
                      expression: "type.enabled"
                    }
                  },
                  [
                    _c("b", { staticClass: "ml-1" }, [
                      _vm._v(_vm._s(type.name))
                    ])
                  ]
                ),
                _vm._l(type.features, function(feature) {
                  return _c(
                    "div",
                    { key: feature.id },
                    [
                      _c(
                        "vs-checkbox",
                        {
                          staticClass: "ml-8 mt-4 text-base",
                          on: {
                            change: function($event) {
                              return _vm.handleFeatureChange(feature)
                            }
                          },
                          model: {
                            value: feature.enabled,
                            callback: function($$v) {
                              _vm.$set(feature, "enabled", $$v)
                            },
                            expression: "feature.enabled"
                          }
                        },
                        [
                          _c("b", { staticClass: "ml-1" }, [
                            _vm._v(_vm._s(feature.name))
                          ]),
                          _vm._v(_vm._s(" - " + feature.description) + " ")
                        ]
                      )
                    ],
                    1
                  )
                })
              ],
              2
            )
          })
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }