<template>
    <div v-if="featuresByType.length > 0">
        <LabelForm class="mt-0">{{ $t('admin.authorization.features') }}</LabelForm>

        <div class="flex flex-row gap-2 mt-2 mb-4">
            <button class="sk-btn sk-btn-small sk-btn-primary rounded-lg btn-border" @click="changeAll(true)">
                {{ $t('shops.select_all') }}
            </button>
            <button class="sk-btn sk-btn-small sk-btn-primary rounded-lg btn-border" @click="changeAll(false)">
                {{ $t('shops.clear') }}
            </button>
        </div>

        <div v-for="type in featuresByType" :key="type.id">
            <vs-checkbox
                class="mt-4 text-base"
                v-model="type.enabled"
                @change="handleTypeChange(type)"
            >
                <b class="ml-1">{{ type.name }}</b>
            </vs-checkbox>

            <div v-for="feature in type.features" :key="feature.id">
                <vs-checkbox
                    class="ml-8 mt-4 text-base"
                    v-model="feature.enabled"
                    @change="handleFeatureChange(feature)"
                >
                    <b class="ml-1">{{ feature.name }}</b>{{ ` - ${feature.description}` }}
                </vs-checkbox>
            </div>
        </div>
    </div>
</template>

<script>

import loader from "@/modules/Shared/Mixins/loader";
import AuthorizationService from "@/modules/Admin/Services/AuthorizationService";
import PaginationService from "@/modules/Shared/Services/PaginationService";
import { unifyPaginatedResponse } from "@/modules/Shared/Helpers/apiResponseHelper";
import LabelForm from "@/modules/Shared/Components/form/LabelForm.vue";

export default {
  name: "FeaturesCheckboxes",
  components: {LabelForm},
  mixins: [loader],
  props: {
      object: {
          type: Object,
          required: true
      }
  },
  data() {
    return {
      featuresByType: [],
    }
  },
  watch: {
      'object.scope_type': {
          deep: true,
          immediate: true,
          async handler() {
              await this.loadFeatures()
          }
      }
  },
  methods: {
      async loadFeatures() {
          await this.load(async () => {
              const features = await unifyPaginatedResponse(AuthorizationService.getFeatures, PaginationService.parseParameters({
                  per_page: 30,
                  filters: [
                      {
                          field: 'scope_type',
                          value: this.object.scope_type.name
                      }
                  ]
              }))

              const self = this

              this.featuresByType = features.reduce(function (acc, feature) {
                  const type = acc.find(type => type.id === feature.feature_type.id)

                  if (type) {
                      type.features.push({
                          ...feature,
                          enabled: self.object.enabled_features.map(enabledFeature => enabledFeature.id).includes(feature.id)
                      })

                      return acc
                  }

                  acc.push({
                      ...feature.feature_type,
                      enabled: false,
                      features: [{...feature, enabled: self.object.enabled_features.map(enabledFeature => enabledFeature.id).includes(feature.id)}]
                  })

                  return acc
              }, []).map(type => ({
                  ...type,
                  enabled: type.features.every(feature => feature.enabled)
              }))
          })
      },
      changeAll(enabled) {
          this.featuresByType.forEach(function (type) {
              type.enabled = enabled
              type.features.forEach(function (feature) {
                  feature.enabled = enabled
              })
          })
      },
      handleTypeChange(type) {
          type.features.map(feature => feature.enabled = type.enabled)
      },
      handleFeatureChange(feature) {
          const type = this.featuresByType.find(type => type.id === feature.feature_type.id)
          type.enabled = type.features.every(feature => feature.enabled)
      },
      getEnabledFeatures() {
          return this.featuresByType.reduce(function (acc, type) {
              type.features.filter(feature => feature.enabled).map(feature => acc.push(feature.id))
              return acc
          }, [])
      }
  }
};
</script>
